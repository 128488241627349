body{
    background-color:#043249;
}
/* Navbar CSS */
.logo{
    width:120px;
    height:80px;
}
.navClass{
    background-color: #043249;
}
.pages{
    color:white;
    margin-right: 2rem;
    font-size: 1.2rem;
}
.pages:hover{
    color:white;
}
.toggleicon{
    background-image: url("./components/toggle-img.svg");
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    border-color:white;
}
.dropitem{
    background-color: #043249;
    border:1px solid #043249;
    min-width: 12rem;   
}
.dropdown:hover .dropitem{
    display: block;
}

.dropdown-item{
    color:white;
    padding-top: 0rem;
    padding-bottom: 1rem;
    background-color:#043249; 
}
.dropdown-item:hover{
    background-color:#043249;
    color:bisque; 
}
.linenav{
    border: none;
    
}
.industry_name{
    color:white;
    font-family:HORIZON;
    font-size: 2rem;;
}
/*Footer CSS*/
.foot{
    background-color: #043249;
    color: white;
}
.contact-us{
    padding-top: 40px;
    text-align: left;
    padding-left: 60px;
    font-size: 1.5rem;
}
hr:not([size]) {
    height: 2px;
}
.line{
    width: 50%;
    height: 100px;
    margin-left: 5px;
    margin-right: auto;
    color: white;
}
.Address{
    text-align: left;
    padding-left: 60px;
    padding-bottom: 20px;
   
}
/*Home CSS*/
.bgimg{
    /* background-image: url("./components/taslabg.jpg"); */
    /* opacity: 0.7; */
    /* background-color: #dc6835; */
    background-color:azure ;
    width: auto;
    height: 720px;  
    background-repeat:no-repeat;  
    

}
.homebg{
    padding-top: 108px;;
}
.mright{
    margin-left: 60%;
}
.welcome{
    margin-top: 100px;
    color:#34726a;
    font-family: 'Times New Roman', Times, serif;
    font-size: 3rem;
}
/*About CSS*/
.aboutdiv{
    background-color: #043249;
}
.aboutimg{
    border-radius: 0.5rem;
    padding:1.5rem;
    width:85%;
}
.aboutinfo{
    margin-top: 116px;
    background-color: #dc6835;
    margin-left: 20px;
    border-radius: 1.5rem;
}
.bgabout{
    background-image: url("./components/abg.jpg");
    background-size: 1000px 400px;
    width: auto;
    height: 800px;  
    background-repeat:no-repeat;   

}
.heading{
    padding-top: 2rem;

}
.para{
    text-align: justify;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}
.fonts{
    padding-bottom: 2rem;
    font-size: 2rem;
   
}

/* .pfont{
    visibility: hidden;
} */
/*Contact CSS*/
.contactdiv{
    background-color: #043249;
}
.contactinfo{
    margin-top: 120px;
    background-color: azure;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 1.5rem;
}
.enheading{
    padding-top: 8rem;
}
.enimg{
    border-radius: 0.5rem;
    padding:1.5rem;
    width:85%;
}
.content{
    padding-top: 2rem;
}
.card-body{
    background-color: #e7f2f7;
}

/*Carousel CSS*/
.taslacare {
    height: 600px;
    /* padding-left: 10%; */
    /* padding-right: 10%; */
    /* filter: sepia(100%) saturate(200%) brightness(70%) hue-rotate(330deg); */
    /* background:linear-gradient(rgba(0,184,255,0.45),rgba(0,184,255,0.45)),url('D:\React Directory\tasla-app\src\components\car2.jpg');            */
}
.carouselmsg{
    font-weight: bold;
}
.carouselpara{
    font-size: 2rem;
}
/*  */

/* Iron Tasla CSS */
.commonforall{
    background-color: azure;
    border-radius: 1rem;
    margin-top:96px;
}
.img-products{
    width:100%;
    height:300px;
    border: 0.01px solid grey;
}
.img-tasla{
    width:100%;
    height:50%;
}
.rowtasla2{
    align-items: center;
    position: relative;
}
.img-tagari{
    width: 100%;
}